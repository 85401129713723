<template>
  <div>   
    <CCard>
      <CCardHeader> 
        <CIcon name="cil-calendar" /> Agenda 
         <div class="card-header-actions">
              <a                 
                class="btn btn-warning card-header-action" 
                rel="noreferrer noopener" 
                @click="fetchServices()"                
              >
                Refrescar
              </a>
          </div>
      </CCardHeader>
      <CCardBody>
        <calendar-view
          id="calendar"
          :events="events"
          enable-drag-drop
          :show-date="showDate"
          :displayPeriodUom="displayPeriod"
          currentPeriodLabel="icons"
          :class="themeClasses"
          @click-date="onClickDay"
          @click-event="onClickEvent"
          @drop-on-date="onDrop"
          @show-date-change="setShowDate"
          ref="calendarView"
          :disablePast="true"
        >
          <calendar-view-header
            slot="header"
            slot-scope="{ headerProps }"
            :header-props="headerProps"
            @input="setShowDate"
          />
        </calendar-view>
      </CCardBody>
      <CCardFooter>
        <CForm inline>
          <CSelect
            :options="['month', 'week']"
            :value.sync="displayPeriod"
            label="Vista"
            add-wrapper-classes="ml-2"
          />
          <h4 class="ml-auto my-auto">
            <CBadge v-if="message" color="primary">{{ message }}</CBadge>
          </h4>
        </CForm>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import {
  CalendarView,
  CalendarViewHeader,
  CalendarMathMixin
} from "vue-simple-calendar";
import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";
import moment from "moment";

const currDate = new Date();

export default {
  name: "ProviderCalendar",  
  mixins: [CalendarMathMixin],
  data: function() {
    return {
      message: "",
      showDate: currDate,
      displayPeriod: "month",
      events: [],
      useDefaultTheme: true,
      useHolidayTheme: true,
      userProfile: null,
      totalServices: []
    };
  },
  components: {
    CalendarView,
    CalendarViewHeader
  },
  created(){
  this.userProfile = JSON.parse(this.$store.getters.userProfile);
  console.log(this.userProfile)
  this.watchs()
  this.fetchServices()
  },
  computed: {
    themeClasses() {
      return {
        "theme-default": this.useDefaultTheme,
        "holiday-us-traditional": this.useHolidayTheme,
        "holiday-us-official": this.useHolidayTheme
      };
    }
  },
  methods: {
    setShowDate(d) {
      this.showDate = d;
    },
    onClickDay(d) {
      this.message = `You clicked: ${d.toLocaleDateString()}`;
      this.$router.push({ 
        name: 'provider-calendar-list',
        params: {
          date: d,
          services: this.totalServices
        }
      })
    },
    onClickEvent(e) {
      this.message = `You clicked: ${e.title}`;
    },
    onDrop(event, date) {
      this.message = `Moved: ${event.title ||
        event.id} to ${date.toLocaleDateString()}`;
      // Determine the delta between the old start date and the date chosen,
      // and apply that delta to both the start and end date to move the event.
      const eLength = this.dayDiff(event.startDate, date);
      event.originalEvent.startDate = this.addDays(event.startDate, eLength);
      event.originalEvent.endDate = this.addDays(event.endDate, eLength);
    },
    fetchServices(){
      let startDate = moment(currDate).format('YYYY-MM-DDT')+'00:00:00.000Z'
      let endDate = moment(startDate).add(15, 'days').format('YYYY-MM-DDT') + '23:59:59.000Z'
      let payload = {
        userId: this.userProfile.usuario.id,
        startDate,
        endDate,
        city: {
          value: this.userProfile.ciudades.id,
          label: this.userProfile.ciudades.nombre,
        },
      }
      this.$store.dispatch("fetchProviderServicesAvailable", payload).then(data => {
        if(data){
          this.events = data.events
        }
      }).catch((error) => {
        console.log(error)
      })     
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defCalendarProviderServices") {
          this.totalServices   = this.$store.getters.calendarProviderServices
        }
      });
    },
  }
};
</script>

<style lang="scss">
@import "../../../assets/scss/style";
#calendar {
  color: $body-color;
  height: 63vh;
  margin-left: auto;
  margin-right: auto;
}
.theme-default .cv-event {
  background-color: #c8ced3;
  border-color: $table-border-color;
  color: $body-color;
}
.theme-default .cv-event.success {
  background-color: $success;
  border-color: lighten($success, 5%);
}
.theme-default .cv-event.danger {
  background-color: $danger;
}
.theme-default .cv-event.primary {
  background-color: #20a8d8;
}
.theme-default .cv-event.orange {
  background-color: #f8cb00;
  border-color: lighten(#f8cb00, 5%);
}
.theme-default .cv-day.today {
  background-color: $gray-300;
}
.theme-default .cv-day.today > .cv-day-number {
  background-color: #20a8d8;
  border-radius: 50%;
  position: absolute;
  left: 0;
}
.theme-default .cv-day.past {
  background-color: $body-bg;
}
.theme-default .cv-day.outsideOfMonth {
  background-color: $body-bg;
}
.theme-default .cv-weeks,
.theme-default .cv-day {
  border-color: $table-border-color;
}
.theme-default .cv-header,
.theme-default .cv-header-days,
.theme-default .cv-header-day {
  background-color: #e4e5e6;
  border-color: $table-border-color;
  border-style: solid;
}
.theme-default .cv-header-nav > button {
  color: $body-color;
  background-color: $input-bg;
  border-color: $input-border-color;
}
.theme-default .cv-day.draghover {
  box-shadow: inset 0 0 0.2em 0.2em #321fdb;
}
</style>
